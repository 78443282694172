function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/leonardo-cunha-208084146/"
            target="_blank"
            rel={'noreferrer'}
          >
            <span className="fab fa-linkedin" aria-hidden="true"></span>
            <span className="sr-only">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/lmscunha"
            target="_blank"
            rel={'noreferrer'}
          >
            <span className="fab fa-github-square" aria-hidden="true"></span>
            <span className="sr-only">Github</span>
          </a>
        </li>
      </ul>
      <p>
        <small>&copy; 2025 Leonardo Cunha. All rights reserved.</small>
      </p>
    </footer>
  )
}

export { Footer }
